@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes gradientSlide {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.header-gradient {
  background-size: 200% 100%;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientSlide 10s linear infinite;
}

.relative {
  position: relative;
}

.group:hover .hover-gradient:hover {
  background-image: linear-gradient(to right, #287aff, #c2aaff);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.group:hover .hover-gradient {
  color: transparent;
}

.group:hover .hover-gradient {
  animation: gradientSlide 3s ease-in-out infinite alternate;
}

.group:hover .group-hover\:top-0 {
  top: 0;
}

.group:hover span {
  color: #000; /* Change the text color on hover */
}

.block-container .btn-back {
  position: absolute;
  inset: 0;
  z-index: -1;
  width: inherit;
  height: inherit;
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: bottom right;
  transform: rotateZ(15deg);
  will-change: transform;
  box-shadow: 16px 0 40px #e4e4e4;
}

.block-container .btn-back-red {
  background: linear-gradient(135deg, #ff4b1f -20%, #ff9068 120%);
}

.block-container .btn-back-green {
  background: linear-gradient(135deg, #adfda2 -20%, #11d3f3 120%);
}

.block-container .btn-back-yellow {
  background: linear-gradient(135deg, #f7971e -20%, #ffd200 120%);
}

.block-container .btn-back-blue {
  background: linear-gradient(135deg, #0061ff -20%, #60efff 120%);
}

.block-container .btn-back-orange {
  background: linear-gradient(135deg, #ff0f7b -20%, #f89b29 120%);
}

.block-container .btn-back-pink {
  background: linear-gradient(135deg, #e81cff -20%, #40c9ff 120%);
}

.block-container .btn-back-black {
  background: linear-gradient(135deg, #0a1647 -20%, #e4e7e4 120%);
}

.block-container .btn-front {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: inherit;
  height: inherit;
  background-color: #ffffff33;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: top left;
  overflow: hidden;
}
.block-container:hover > .btn-front {
  transform: translateZ(80px) translateY(-5px) rotateX(15deg) rotateY(15deg);
}

.about-hero-gradient {
  background: hsla(207, 29%, 6%, 1);
  background: linear-gradient(45deg, hsla(207, 29%, 6%, 1) 40%, hsla(305, 72%, 18%, 1) 100%);
  background: -moz-linear-gradient(45deg, hsla(207, 29%, 6%, 1) 40%, hsla(305, 72%, 18%, 1) 100%);
  background: -webkit-linear-gradient(45deg, hsla(207, 29%, 6%, 1) 40%, hsla(305, 72%, 18%, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0B1014", endColorstr="#4F0D4A", GradientType=1);
  background-attachment: fixed;
  background-size: cover;
}

@keyframes gradient-border {
  0% {
    border-image: linear-gradient(90deg, #6ee7b7, #3b82f6, #9333ea, #f472b6) 1;
  }
  100% {
    border-image: linear-gradient(90deg, #f472b6, #6ee7b7, #3b82f6, #9333ea) 1;
  }
}

.profile-gradient-border {
  animation: gradient-border 3s infinite linear;
  border-image-slice: 1;
}

.profile-tilt:hover {
  transform: scale(1.10) rotateZ(20deg);
}

.custom-shadow {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); /* Default state shadow */
}

.custom-shadow:hover {
  box-shadow: 0 20px 25px rgba(110, 146, 255, 0.5); /* Shadow on hover */
}